<template>
  <x-drop-down
    :items="items"
    :visible-activator="visibleActivator"
    @select="(item) => emit('select', item)"
  >
    <template #default="{ isActive, props }">
      <v-btn
        v-bind="props"
        size="large"
        :min-width="buttonMinWidth"
        :max-width="buttonMaxWidth"
        variant="flat"
        class="dropdown-button"
        :class="$vuetify.display.name"
      >
        <!--  activator fio+organization+icon  -->
        <div class="w-100 d-flex align-center">
          <slot />
          <v-spacer class="flex-grow-1" />
          <v-icon
            v-if="dropdownIcon"
            class="drop-down-icon text-beton align-self-baseline"
            :class="isActive && 'active'"
            >$arrow-down</v-icon
          >
        </div>
      </v-btn>
    </template>
  </x-drop-down>
</template>

<script setup lang="ts">
defineOptions({
  name: 'XDropDownButton',
});

type TItem = { label: string; icon?: string; value?: string; callback?: () => {} };

const {
  visibleActivator = true,
  dropdownIcon = false,
  buttonMinWidth = 'unset',
  buttonMaxWidth = 'unset',
} = defineProps<{
  items: Array<TItem>;
  visibleActivator: boolean;
  dropdownIcon: boolean;
  buttonMinWidth?: string;
  buttonMaxWidth?: string;
}>();

const emit = defineEmits<{
  (_eventName: 'select', _item: TItem): void;
}>();
</script>

<style lang="scss" scoped>
.dropdown-button {
  display: block;
  padding-left: 6px;
  padding-right: 6px;
}

.drop-down-icon {
  margin-top: 2px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &.active {
    transform: rotate(180deg);
  }
}
</style>
