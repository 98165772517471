<template>
  <v-app-bar
    :height="$vuetify.display.smAndUp ? 68 : 56"
    flat
    class="app-bar d-flex align-center px-3 px-sm-4"
  >
    <!--  logo-icon+title  -->
    <div
      class="logo-link"
      :class="$vuetify.display.name"
    >
      <nuxt-link :to="homePage">
        <logo
          width="36"
          height="36"
          class="mr-1"
        />
        <text-logo
          v-if="$vuetify.display.smAndUp"
          width="100"
          height="14"
        />
      </nuxt-link>
    </div>

    <!--  navigation tabs  -->
    <v-tabs
      v-if="showTabsNavigation"
      class="nav-tabs"
      :class="$vuetify.display.name"
      color="beton80"
    >
      <!--  скрытие таб выделен по умолчанию для роутов вне navigationTabs -->
      <!--  это позволяет выделять корректно только exact path -->
      <!--  так же важно использовать параметр route.name  -->
      <v-tab
        key="hidden-tab-key"
        to="/"
        text="hidden"
        class="d-none"
      />
      <v-menu offset="4">
        <template #activator="{ isActive, props }">
          <v-btn
            class="text-body-1"
            height="100%"
            rounded="0"
            variant="flat"
            v-bind="props"
          >
            <span>Каталог</span>
            <v-icon
              icon="$arrow-down"
              end
              size="16"
              class="icon-arrow-down text-beton64"
              :class="{ opened: isActive }"
            />
          </v-btn>
        </template>

        <v-list class="pa-2">
          <v-list-item
            key="catalog-item-prepend"
            title="Общий"
            class="pa-2"
            :to="{ name: 'catalog' }"
          />

          <v-list-item
            v-for="(item, key) in catalogMenuItems"
            :key="`catalog-item-${key}`"
            :title="item.text"
            :to="{ name: 'catalog-organizationId', params: { organizationId: item.id } }"
          />
        </v-list>
      </v-menu>

      <v-btn
        key="feed-calculation-key"
        :to="{ name: 'feed-calculation' }"
        class="text-body-1"
        text="Калькулятор корма"
        height="100%"
        rounded="0"
        variant="flat"
      />
    </v-tabs>
    <v-spacer class="flex-grow-1" />

    <!--  Корзина  -->
    <v-btn
      icon
      to="/cart/"
      :active="false"
      variant="text"
      class="mr-6"
    >
      <v-badge
        v-if="cartStore.cartItemsCount > 0"
        color="red"
        :content="cartStore.cartItemsCount"
        :max="99"
      >
        <v-icon
          size="x-large"
          color="beton80"
          >$cart</v-icon
        >
      </v-badge>
      <v-icon
        v-else
        size="large"
        color="beton80"
        >$cart</v-icon
      >
    </v-btn>

    <!--  profile button and menu dropdown  -->
    <x-drop-down-button
      :items="profileMenuItems"
      :visible-activator="authService.isLoggedIn && !!userStore.currentUser"
      :dropdown-icon="!$vuetify.display.xs"
      :button-min-width="!$vuetify.display.xs ? '190' : undefined"
      button-max-width="235"
      @select="selectProfileMenuItem"
    >
      <v-avatar
        class="avatar"
        :size="32"
      >
        {{ avatarLetters }}
      </v-avatar>
      <div
        v-if="$vuetify.display.smAndUp"
        class="d-flex flex-column text-left"
        :style="{ maxWidth: `${235 - 100}px` }"
      >
        <div
          v-if="userStore.currentUser"
          class="text-body-1 text-beton text-overflow"
        >
          {{ `${userStore.currentUser.firstName} ${userStore.currentUser.lastName}` }}
        </div>
        <div
          v-if="userStore.organizationName"
          class="text-body-2 text-beton48 text-overflow"
          style="letter-spacing: -0.05rem !important"
        >
          {{ userStore.organizationName }}
        </div>
      </div>
    </x-drop-down-button>

    <v-btn
      v-if="!authService.isLoggedIn && !userStore.currentUser"
      class="font-weight-bold my-4 mr-2"
      variant="flat"
      color="primary"
      data-test-id="btn-sign-in"
      @click="signInSso"
    >
      <p class="text-button">Войти</p>
    </v-btn>

    <x-dialog
      v-model="isSupportDialogVisible"
      :max-width="$vuetify.display.xs ? '95%' : 360"
    >
      <div
        class="text-beton text-center mx-auto mb-16"
        style="max-width: 290px"
      >
        <h2 class="mb-4">Контакты службы поддержки</h2>
        <div class="text-body-1">
          <p class="mb-2">
            Наши специалисты всегда готовы ответить на ваши вопросы и помочь решить любые проблемы.
          </p>
          <p class="mb-2">Свяжитесь с нами по указанным контактам</p>
          <p class="mb-2">
            Тел.:
            <a
              href="tel:+79652822626"
              class="text-h4 text-blue"
              >+7 965 282-26-26</a
            >
          </p>
          <p class="mb-2">
            Почта:
            <a
              href="mailto:info@fishplan.ru"
              class="text-h4 text-blue"
              >info@fishplan.ru</a
            >
          </p>
        </div>
      </div>
    </x-dialog>
  </v-app-bar>
</template>

<script setup lang="ts">
import { AuthService } from '~/app-modules/auth/services/auth.service';
import { UsersStoreProvider } from '~/app-modules/users/stores/users.store';
import { CartStoreProvider } from '~/app-modules/cart/stores/cart.store';
import { OrganizationsStoreProvider } from '~/app-modules/organizations/stores/organizations.store';

defineOptions({
  name: 'MainHeader',
});

const authService = useIOC(AuthService);
const userStore = useIOC(UsersStoreProvider).getStore();
const cartStore = useIOC(CartStoreProvider).getStore();
const organizationsStore = useIOC(OrganizationsStoreProvider).getStore();
const route = useRoute();

const { from = '/catalog/' } = route.query as { from?: string };

const avatarLetters = computed(() =>
  userStore.currentUser
    ? `${userStore.currentUser.firstName[0]}${userStore.currentUser.lastName[0]}`
    : ''
);

const isSupportDialogVisible = ref(false);

// Навигация скрыта для следующих страниц
const showTabsNavigation = computed(
  () => !['/auth', '/lk'].some((path) => route.path.includes(path))
);

const catalogMenuItems = computed(
  () =>
    organizationsStore.organizations?.map((org) => ({
      text: org.name,
      id: org.id,
    })) || []
);

type TItem = { label: string; icon?: string; value?: string; callback?: () => {} };

const profileMenuItems: Array<TItem> = [
  { label: 'Личный кабинет', icon: '$edit-pen', value: 'lk' },
  { label: 'Служба поддержки', icon: '$headphones', value: 'support' },
  { label: 'Выход', icon: '$logout', value: 'logout' },
];
function selectProfileMenuItem(item: TItem) {
  switch (item.value) {
    case 'lk': {
      window.location.href = new URL('/lk/', useRuntimeConfig().public.app.ssoUrl).toString();
      break;
    }
    case 'support': {
      isSupportDialogVisible.value = true;
      break;
    }
    case 'logout': {
      authService.logout();
      if (route.meta.requiresAuth === undefined || route.meta.requiresAuth) {
        window.location.href = '/catalog/';
      } else {
        window.location.reload();
      }
      break;
    }
    default:
  }
}

async function signInSso() {
  window.location.href = await authService.getSsoSignInUrl(from, userStore.currentUser);
}

const homePage = computed(() => {
  return authService.isLoggedIn && !!userStore.currentUser
    ? useRuntimeConfig().public.app.ssoUrl
    : 'https://fishplan.ru';
});
</script>

<style lang="scss" scoped>
.app-bar {
  border-bottom: 1px solid rgb(var(--v-theme-beton12));
}

.icon-arrow-down {
  margin-top: 2px;
  transition: 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  @include extend-action-zone();

  &.opened {
    transform: rotate(180deg);
  }

  &:hover {
    color: rgb(var(--v-theme-beton)) !important;
  }
}

.logo-link {
  display: flex;
  align-items: center;

  & a {
    display: flex;
    align-items: center;
  }

  &.xs {
    margin-right: 8px;
    margin-left: 8px;
  }

  &.sm {
    width: 224px;
  }

  &.md {
    width: 280px;
  }

  &.lg,
  &.xl {
    width: 320px;
  }
}

.nav-tabs {
  flex: 1 1 auto;

  &:deep(.v-slide-group__prev),
  &:deep(.v-slide-group__next) {
    min-width: unset !important;
    flex: 0 1 28px;
  }
}

.nav-tabs.xs {
  &:deep(.v-slide-group__content .v-btn) {
    padding-left: 8px;
    padding-right: 8px;
  }
  &:deep(.v-slide-group__content .v-btn__content) {
    white-space: unset;
    width: min-content;
  }
}

.avatar {
  margin-right: 6px;
  color: white;
  font-stretch: condensed;
  background: linear-gradient(18deg, #194a7f 15.64%, #246da4 63.13%, #2e8bc4 91.06%);
}

.logo-icon {
  width: 23px;
  height: 32px;
  max-width: 23px;

  &.small {
    width: 30px;
    height: 36px;
    max-width: 36px;
  }
}

.dropdown-button {
  display: block;
  padding-left: 6px;
  padding-right: 6px;
}

:deep(.dropdown-button__xs) {
  padding-right: unset;
}

.drop-down-icon {
  margin-top: 2px;
  transition: 0.2s cubic-bezier(0.4, 0, 0.2, 1);

  &.active {
    transform: rotate(180deg);
  }
}

.text-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
